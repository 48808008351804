@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "choices.js/public/assets/styles/choices.css";

.center {
  margin: auto;
  width: 80%;
  padding: 20px;
}

table {
  border-collapse: collapse;
  width: 100%;
}

th, td {
  text-align: left;
  padding-left: 1.5rem;
  border-bottom: 1px solid rgba(221, 221, 221, 0.027);
}

th {
  text-align: left;
  padding-bottom: 0.5rem;
}

tr:hover {
  background-color: #f5f5f5;
}

.contract-table {
  font-size: 8px;
}

.contract-td {
  padding-left: 0;
}

.date-in-heading {
 float: right;
}

.label {
  color: white;
  padding: 1px;
  border-radius: 5px;
}

.success {background-color: $success;}
.info {background-color: $info;}
.warning {background-color: $warning;}
.danger {background-color: $danger;}